import { STATUS_CONFIRM_EMAIL } from '@/constants/status';
import i18n from '@/lang/i18n';

export const getStatusNameById = (statusID) => {
  const status = STATUS_CONFIRM_EMAIL.find(status => status.status === statusID);
  return i18n.t(status?.name)
}

export const getColorTextStatusById = (statusID) => {
  const status = STATUS_CONFIRM_EMAIL.find(status => status.status === statusID);
  return status?.color_text
}
