<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
    >
      <v-card>
        <v-card-title>
          <img src="@/assets/images/dialog/question.svg" />
          <span>{{ $t('user_management.title_popup_confirm')}}</span>
        </v-card-title>
        <v-card-text>{{ $t('popup.popup_send_request_active_user')}}</v-card-text>
        <v-card-actions>
          <common-button class="left-button-popup" @action="close" :label="buttonCancel"/>
          <common-button class="right-button-popup btn-red-mid" @action="submit" :label="buttonSend"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/commonApp/Button.vue';
export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemSelect: null,
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    buttonCancel() {
      return this.$t("user_management.button_cancel");
    },
    buttonSend() {
      return this.$t("user_management.button_send");
    }
  },
  methods: {
    close() {
      this.$emit('close');
      this.itemSelect = null;
    },
    submit() {
      this.$emit('submit');
      this.itemSelect = null;
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>
